import { useState } from 'react'
import { Combobox, InputBase, useCombobox } from '@mantine/core'
import { Icon } from '../icon'
import styled from 'styled-components'
import { FilterProps } from './filter.types'

export const StyledFilterWrapper = styled.div`
  ${({ theme }) => `
  position: relative;

  .mantine-InputBase-wrapper {
    background-color: white !important;
  }

  .mantine-InputBase-input {
    border: none;
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: ${theme.colors.gray70};
    }

    p {
      display: inline-block;
      margin: 5px 0;
    }
  }
`}
`

const StyledIconWrapper = styled.div`
  right: 1rem;
  cursor: pointer;
  pointer-events: auto;
  padding-left: 5px;
  display: flex;
  justify-content: center;
  svg {
    width: 1rem;
    height: 1rem;
  }
`

const StyledFilterLabelText = styled.p<{ disabled?: boolean }>`
  ${({ theme, disabled }) => `
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  font-size: 14px;
  color: ${disabled ? 'gray' : 'black'};
  background-color: white;
  margin: 5px;

  &:hover {
    background-color: ${disabled ? 'white' : theme.colors.gray70};
  }
`}
`

const StyledLabelText = styled.p`
  display: inline-block;
`

export const Filter = ({
  label,
  options,
  selectedValue,
  onChange,
  testId,
}: FilterProps) => {
  const [currentLabel, setCurrentLabel] = useState<string | null>(
    selectedValue || label
  )
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      setDropdownOpen(false)
    },
  })

  const handleToggleDropdown = () => {
    if (isDropdownOpen) {
      combobox.closeDropdown()
    } else {
      combobox.openDropdown()
    }
    setDropdownOpen(!isDropdownOpen)
  }

  return (
    <StyledFilterWrapper>
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
          const selectedOption = options.find((option) => option.value === val)
          setCurrentLabel(selectedOption ? selectedOption.label : label)
          onChange(val)
          combobox.closeDropdown()
          setDropdownOpen(false)
        }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            onClick={handleToggleDropdown}
            rightSectionPointerEvents="none"
            data-testid={testId}
            style={{
              borderRadius: '4px',
              fontSize: '14px',
              fontWeight: 500,
              color: 'black',
              backgroundColor: 'white',
              cursor: 'pointer',
              display: 'inline-block',
            }}
          >
            <StyledLabelText>
              {selectedValue ? currentLabel : label}
            </StyledLabelText>
            <StyledIconWrapper onClick={handleToggleDropdown}>
              <Icon
                name={isDropdownOpen ? 'chevron-up' : 'chevron-down'}
                size={8}
                color="black"
              />
            </StyledIconWrapper>
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown
          style={{
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
            zIndex: 1000,
            position: 'absolute',
            width: 'auto',
            minWidth: 'fit-content',
            maxWidth: 'fit-content',
          }}
        >
          <Combobox.Options>
            {options.map((item, index) => (
              <Combobox.Option
                value={item.value}
                key={item.value}
                disabled={
                  index === 0
                    ? item.value === '' && !selectedValue
                    : item.value === selectedValue
                }
                style={{
                  margin: '0.5rem 0',
                }}
              >
                <StyledFilterLabelText
                  disabled={
                    index === 0
                      ? item.value === '' && !selectedValue
                      : item.value === selectedValue
                  }
                  data-testid={`filter-${item.value}`}
                >
                  {item.label}
                </StyledFilterLabelText>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </StyledFilterWrapper>
  )
}
